import * as React from "react";
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";
import { green, orange, purple } from "@mui/material/colors";
import paper_texture from "./assets/paper_texture.jpg";

//Global styles

const theme = createTheme({
  palette: {
    // mode: 'light',
    background: {
      default: "#e6f8ff",
    },
    primary: {
      light: "#E4C100", //#066
      main: "#E4C100",
      dark: "#DF697F",
      contrastText: "#fff",
    },
    secondary: {
      light: "#E4C100",
      main: "#E4C100",
      dark: "#DF697F",
      contrastText: "#fff",
    },
  },
  typography: {
    h1: {
      fontWeight: "800",
      fontSize: "35px",
      fontFamily: "RalewayBold",
      fontStyle: "normal",
      lineHeight: "1",
      letterSpacing: "-0.02em",
    },
    h2: {
      // For Sale and Explore Headers Text
      color: "white",
      fontWeight: "800",
      fontSize: "35px",
      fontFamily: "RalewayBold",
      fontStyle: "normal",
      lineHeight: "1",
      letterSpacing: "-0.02em",
    },
    h3: {
      fontFamily: "RalewaySemiBold",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "20px",
      letterSpacing: "-0.02em",
      marginTop: "0",
      textAlign: "left" as "left",
    },
    h6: {
      fontFamily: "Raleway",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "18px",
      marginTop: "0",
      letterSpacing: "-0.01em",
      textAlign: "left" as "left",
    },
    body1: {
      fontFamily: "Raleway",
      fontStyle: "medium",
      fontWeight: "600",
      fontSize: "16px",
      letterSpacing: "0.01em",
      color: "black",
    },
    body2: {
      //Secondary Text
      fontFamily: "Raleway",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "14px",
      letterSpacing: "0.01em",
      color: "#E4C100", //Use this color to specidy the color of secodary text (USDC price etc)
      textShadow: "0 0 1px black",
    },
    subtitle2: {
      //Landing page description
      fontFamily: "Raleway",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "16px",
      letterSpacing: "0.01em",
    },
    subtitle1: {
      //NavBar Text
      fontFamily: "Raleway",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "16px",
      marginTop: "0",
      letterSpacing: "-0.01em",
      textAlign: "left" as "left",
      color: "black",
    },
  },
  components: {
    //AppBar
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: "#DBDADD",
          position: "fixed", //'absolute' 'fixed' 'relative' 'static' 'sticky',
        },
      },
    },
    //Buttons
    MuiButton: {
      variants: [
        {
          // This is the styling PrimaryButtons
          props: { className: "primary" },
          style: {
            height: "45px",
            borderRadius: "8px",
          },
        },
        {
          // This is the styling SecondaryButtons
          props: { className: "secondary" },
          style: {
            height: "45px",
            borderRadius: "8px",
            margin: "2px",
          },
        },
        {
          // This is the styling tertiaryButtons
          props: { className: "tertiary" },
          style: {
            height: "45px",
            margin: "2px",
          },
        },
        {
          // This is the styling of the Desktop navigation bar items (and login and Sign Up Buttons)
          props: { className: "navigation" },
          style: {
            height: "30px",
            color: "black",
          },
        },
        {
          // This is the styling of login and signup buttons
          props: { className: "auth" },
          style: {
            height: "45px",
            borderRadius: "10px",
            margin: "5px",
            backgroundColor: "white",
            color: "black",
            "&:hover": {
              backgroundColor: "#fff6e8",
              color: "black",
            },
          },
        },
      ],
    },
    //Accordians
    MuiAccordion: {
      variants: [
        {
          props: { className: "primary" },
          style: {
            borderRadius: "8px",
          },
        },
      ],
    },
    //Dialog PopUps (login /sign up etc)
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundColor: "#E6F8FF",
          borderRadius: "16px",
        },
      },
    },
    //CardItem
    MuiCard: {
      variants: [
        {
          props: { className: "primary" },
          style: {
            borderRadius: "2px",
            backgroundImage: `url(${paper_texture})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            border: "none",
            boxShadow: "0 8px 3px rgba(0,0,0,0.50)",
            "&:before, &:after": {
              content: '""',
              height: "2px",
              position: "absolute",
              left: "0",
              right: "0",
              WebkitClipPath:
                "polygon(0% 0%, 5%  100%, 10% 0%, 15%  100%, 20% 0%, 25% 100%, 30% 0%, 35%  100%, 40% 0%, 45%  100%, 50% 0%, 55%  100%, 60% 0%, 65%  100%, 70% 0%, 75%  100%, 80% 0%, 85%  100%, 90% 0%, 95%  100%, 100% 0%)",
            },
            "&:before": {
              backgroundColor: "#e6f8ff",
              top: 0,
            },
            "&:after": {
              backgroundImage: `url(${paper_texture})`,
              bottom: "-1px",
            },
          },
        },
      ],
    },
    //used for footer components and various background Paper coloring
    MuiPaper: {
      variants: [
        {
          props: { className: "primary" },
          style: {
            background: "#fcbf24",
          },
        },
        {
          props: { className: "secondary" },
          style: {
            background: "#df697f",
          },
        },
      ],
    },
    MuiChip: {
      variants: [
        {
          props: { className: "primary" },
          style: {
            background: "#fcbf24",
          },
        },
      ],
    },
    MuiIconButton: {
      variants: [
        {
          props: { className: "primary" },
          style: {
            color: "black",
          },
        },
      ],
    },
    MuiTextField: {
      variants: [
        {
          props: { className: "primary" },
          style: {
            // background: 'white',
            borderRadius: "8px",
          },
        },
      ],
    },
  },
});

export default theme;

// overides:  TODO
export const globalOverrides = {
  landingPage: {
    text: {
      color: "",
    },
  },
  accordian: {
    text: {
      color: "",
    },
  },
  primaryButton: {
    text: {
      color: "",
    },
  },
  secondaryButton: {
    text: {
      color: "",
    },
  },
  footer: {
    text: {
      color: "",
    },
    icons: {
      color: "",
    },
  },
  socialMedia: {
    color: "",
  },
  navbar: {
    items: {
      color: "",
    },
    mobileDrawer: {
      background: "", //recommended to keep same color as navbar
    },
  },
  cardItem: {
    text: {
      color: "",
    },
  },
  profile: {
    dashboard: {
      text: {
        color: "",
      },
    },
    menuItems: {
      text: {
        color: "",
      },
      navActive: {
        background: "",
      },
    },
  },
  chipItem: {
    chipStyle: {
      // background: 'rgba(255, 255, 255, 0.1)',
      color: "",
    },
  },
  dialogs: {
    text: {
      color: "",
    },
  },
};
