/* 
=================================================================================================================
=================================================================================================================
=== APP CONFIG ==================================================================================================
=================================================================================================================
=================================================================================================================

Please populate the below to make changes to the app.
Please do not remove any fields. 
*/


const appConfig = {
  /* 
  =================================================================================================================
  === GENERAL CONFIGS =============================================================================================
  =================================================================================================================
  */
  general: {
    app: "bitterkomix",
    sdkService: 'fanFireSDK', //fanFireSDK | demo 
    logo: require('./assets/b-logo.png'), // Logo on the Nav bar
    contactDetails: {
      telephone: '(+27) 60 383 0815',
      email: 'info@fanfire.ai'
    },
    socialMedia: {
      // add more possibilities for users
      instagram: 'https://www.instagram.com/bitterkomix/',
      twitter: '',
      facebook: 'https://www.facebook.com/bitterkomix',
      youtube: '',
      custom: [ // TODO: support SVG
        { //Fanfire
          logo: require('./assets/FanFire_Logo_black.png'),
          link: 'https://linktr.ee/fanfire'
        },
        // Add more objects here to add more options
      ]
    },
    // some general throughout configs
    disablePurchasing: false, // Disbles purchasing / listing / unlisting
    openseaLogo: require('./assets/OpenSeaBlue.png'), //Logo to use for Opensea (does not support svg yet)
    metaMaskLogo: require('./assets/MetaMask-Fox.png'), //Logo to use for Metamask
    listViewsErrorImage: require('./assets/placeholderImage.png'), // Image used on error pages when trying to list NFTs
    nftImageNotFoundImage: require('./assets/placeholderImage.png'), // Image to use on card item if image of NFT can not be found
    cardItemViewText: 'View Item', // Text displayed on card item to route into detail view
    serviceFee: 2.5, //Amount for the service
    creatorFee: 10, //Amount the creator gets

    //Some General style overides in the app
    styleOverrides: {
      buttonPrimaryTextColor: {
        // color: 'black'
      }
    }
  },
  /* 
  =================================================================================================================
  === LADNING PAGE ================================================================================================
  =================================================================================================================
  */
  landingPage: {
    // Header on the Landing Page
     header: "", //South Africa's 1st Digital Sketchbook

    // Description on the Landing Page
    description: `Anton Kannemeyer and Conrad Botes embark on their first digital collectibles project. The first comic book will consist of 60 pages each as an NFT. An entire Bitterkomix book will also be for sale to one lucky collector. Each sketchbook consists of an edition of three available to the market, this includes the ‘entire sketchbook’.`,

    // This is the main image displayed on the Landing page
    landingImagePath: require('./assets/landingImage.jpg'), 

    landingVideoPath: undefined, // either landingImagePath or landingVideoPath

    // Landing Page background Image: undefined or image -> require('./assets/<image-path.extension>')
    landingBackroundPath: undefined, //require('./assets/landing_background.jpg'),

    // either byImage or byText - Specifies the CLient name through a logo image
    byImage: require('./assets/f-logo.png'), 
    
    byText: "",  
    // either byImage or byText - Specifies the CLient name through text
  
    // text before image on left
    byPreText: '',

    // Text that appears above the landing image
    // Keep as empty string to exlude
    textAboveImage: '',

    // Link Button that appears below the landing image
    linkBelowImage: {
      include: true, // Boolean to indeicate whether to include or not
      link: 'https://bitterkomix.co.za/', // Where to link to
      text: 'Learn more about Bitterkomix NFTs' // Text for the link
    },

    // Primary Button on Landing Page:
    primary: {
      include: true, // Whether or not to include the button on the landing Page
      text: 'Explore Sketchbook Collection', // Text defined within the button
      routeToPage: 'for-sale' // Where the button will route to (this must be one of the routeable pages)
    },

    // Secondary Button on Landing Page:
    secondary: {
      include: false, // Whether or not to include the button on the landing Page
      text: 'For Sale', // Text defined within the button
      routeToPage: 'for-sale' // Where the button will route to (this must be one of the routeable pages)
    },

    // Text style for landing page
    styleOverrides: {
      text: {
          color: 'gray',
          textShadow: ''
      }
    }
  },
  /* 
  =================================================================================================================
  === BROWSE COLLECTION PAGE ======================================================================================
  =================================================================================================================
  */
  browseCollectionPage: {
    // Whether to include this page in the App
    include: false, 

    // Header on the explore page
    header: "Explore", 

    // undefined or image require('./assets/<image-path.extension>')
    headerImage: require('./assets/top_image1.jpg') 
  },
  /* 
  =================================================================================================================
  === NAV ITEMS ===================================================================================================
  =================================================================================================================
  */
  navItems: {
    navActiveColor: '',
    // mobile carousal color of text nav items
    mobileNavItems: {
      styleOverride: {
        text: {
          color: 'black'
        },
        drawerColor: {
           background: '#DBDADD'
        }
      }
    },
  },
    /* 
  =================================================================================================================
  === PROFILE PAGE ================================================================================================
  =================================================================================================================
  */
  profilePage: {
    // Color of navbar line on active tab (Transactions, my items / liked items)
    navActiveColor: 'black' ,
    dashboard: {
      styleOverride: {
        text: {
          // color: 'white'
        }
      }
    }
  },
  /* 
  =================================================================================================================
  === NFT DETAIL PAGE =============================================================================================
  =================================================================================================================
  */
  nftDetail:{
    // This option adds a custom description to every NFT detail page - Leave as '' to remove
    generalDescription: '',

    // Custom accordian to add to every Detail Page
    customAccordian:{
      include: true, // Whether or not to include this accordian in detail view
      header: 'About Bitterkomix Digital Sketchbook', // Header text of the Custom accordian
      items: [
        'The first Bitterkomix digital book will consist of 60 pages (NFTs), each one offered separately to the market to be collected by their fans. An entire Bitterkomix book will also be for sale to one lucky collector. Each sketchbook consists of an edition of three available to the market, this includes the entire sketchbook. One Artist Edition will also be minted - but not sold - until the artists decide to do so. Each page is sold and offered separately. Eager collectors can opt to collect more pages at a time in order to fill their 60 page sketchbook, or collect the entire sketchbook that is on offer.', // item shown in accoridan dropdown sperated by a divider
        '' // item shown in accoridan dropdown sperated by a divider
      ]
    }
  },
  /* 
  =================================================================================================================
  === FOR SALE PAGE ===============================================================================================
  =================================================================================================================
  */
  forSalePage: {
    // Whether to include this page in the App
    include: true, 

    // Header on the for sale page
    header: "For Sale", 

    // undefined or image require('./assets/<image-path.extension>')
    headerImage: require('./assets/cover.png')
  },
  /* 
  =================================================================================================================
  === NFT VAULT TABLE LIST PAGE ===================================================================================
  =================================================================================================================
  */
  nftVaultTable: {
    // Table to display all vault tokens,
    table: {
      /*
      Table is already populated with: Details, Name, Image, Description and a link to Opensea.
      The below options will have to exist within the attributes (properties) of the token metadata. 
      All options here will be displayed as a column within the table.
      */
      attributesFromMeta: ['Vintage', 'Seal Codes', 'Volume (ml)'],
    }
  },
  /* 
  =================================================================================================================
  === FOOTERS =====================================================================================================
  =================================================================================================================
  */
  footers: {
    // Text on mobile permanent footer,
    mobileText: 'Bitterkomix NFTs',

    footerLogo: require('./assets/by_logo.png'),

    footerTextColor: {
      styleOverrides: {
        text: {
             color: 'black'
        },
        socialMediaFooterTextColor: {
          text: {
            // color: 'black'
          },
        }
      }
    }
  },
  /* 
  =================================================================================================================
  === ATTENDANCE TOKENS PAGE ======================================================================================
  =================================================================================================================
  */
  attendanceTokens: {
    // either byImage or byText - Specifies the CLient name through text
    byImage: undefined, 

    // either byImage or byText - Specifies the CLient name through text
    byText: 'Placeholder Client Name', 

    // Background Image of attandance token page
    background: undefined, 

    // Heading of the Attendance Token page
    heading: "Attendance Token Heading", 

    // Sub-heading of the Attendance Token page
    sub_heading: "Attendance Token sub heading", 
  },
}
export default appConfig

/* 
=================================================================================================================
=== FAQ CONFIG ==================================================================================================
=================================================================================================================

Enter All FAQ options in here. This will populate the FAQ Page.
*/
export const faqs = [
  {
    heading: 'Roadmap',
    questions: [
      {
        question: 'Pre-launch',
        answer: 'Sign up to the waitlist to be the first to know when the Bitterkomix NFT drops.'
      },
      {
        question: '10% Sold',
        answer: 'We will send a Bitterkomix cap to all early adopters.'
      },
      {
        question: '25% Sold',
        answer: 'A selection of 4 signed vintage Bitterkomix copies to 4 lucky collectors.'
      },
      {
        question: '50% Sold',
        answer: 'Airdrop a unique NFT to 5 randomly selected collectors.'
      },
      {
        question: '60% Sold',
        answer: '20 Signed postcard prints to 20 lucky patrons.'
      },
      {
        question: '75% Sold',
        answer: 'An invitation to the Bitterkomix NFT Club Event including drinks with the artists. Plus, digital collaborative art prints to 4 lucky collectors.'
      },
      {
        question: '90% Sold',
        answer: '4 Lucky collectors will each win a unique portrait of themselves done by Conrad Botes (2) and Anton Kannemeyer (2)'
      },
      {
        question: '100% Sold',
        answer: 'All collectors will receive the Bitterkomix NFT Sketchbook Cover Page. One lucky collector will receive an original, hand signed collaborative artwork to the value of R55 000.'
      },
    ]
  },
  {
    heading: 'About Fanfire',
    questions: [
      {
        question: 'What does Fanfire do?',
        answer: 'Fanfire provides solutions for artists, athletes and businesses who recognise the opportunities presented by the third-generation web (“Web3”), such as cryptographic tokens, web wallets, and loyalty economies. The team has worked with a diverse range of partners such as the Cell C Sharks rugby franchise and Care for Wild Rhino Sanctuary to create new Web3 engagement strategies, and is also involved in digitizing the ownership and trade of collectible wines.'
      },
      {
        question: 'Who is Fanfire?',
        answer: `Fanfire is part of the Alphawave Group, a leading technology investment group consisting of businesses with products and services that are hard to replicate. The group is headquartered in Stellenbosch and applies South Africa's signature high-innovation-low-cost creativity to concepts with world-wide application. 
        The company is led by the former Research Chair in IoT at Stellenbosch University (who co-founded Custos, an InfoSec blockchain company, in 2013). It is chaired by an industry veteran who has led two JSE-listed companies, and has decades of experience in technology, entertainment and consumer businesses.`
      },
      {
        question: 'What are NFTs?',
        answer: `A non-fungible token or NFT is merely an ownership certificate.
        In much the same way that a title deed represents the ownership of a house, an NFT is a cryptographically-secured digital certificate that proves ownership of something.
        It can represent ownership of anything; such as commercial rights (of a movie or artwork), it could represent ownership of a certain privilege (such as VIP access to a sport stadium or event), or even represent ownership of a physical collectable (such as a wine vintage) — the possibilities are endless. NFTs are validated and secured on blockchains.`
      },
      {
        question: 'What is a blockchain?',
        answer: `In short, a blockchain is a method of recording information such that it is nearly impossible to alter, hack or cheat the system. 
        A blockchain is underpinned by a decentralised (meaning not one institution has custody over it) digital ledger that tracks transactions using secure and trustless protocols. The most famous blockchains include the Bitcoin- and Ethereum-blockchains. 
        Blockchain and cryptocurrencies are not the same. Bitcoin and USDC are two examples of a cryptocurrency, like rands or dollars, that run on a blockchain. These cryptocurrencies are merely a few lines of code (known colloquially as tokens) in a smart contract that gets executed.`
      },
      {
        question: 'What is USDC?',
        answer: 'USD Coin or USDC is a cryptocurrency (like Bitcoin of Ethereum) which is pegged to the United States dollar, making it extremely stable. USDC was launched in 2018 by a consortium of companies called Centre, and is is fully collateralised while being US dollar-backed.'
      },
      {
        question: `What is a 'Web 3 wallet'?`,
        answer: 'A Web3 wallet is essentially a digital wallet which contains all your digital assets, such as NFTs and other coins (or cryptocurrency).'
      },
      {
        question: 'How can I sell my NFT?',
        answer: `On the Fanfire platform you will be able to 'list' your NFTs for sale by clicking the 'List Item' button on your NFT profile page.`
      },
      {
        question: 'How do I redeem my funds?',
        answer: 'Fanfire uses VALR.com, a trusted digital asset trading platform, for users to convert their cryptocurrency into rands. VALR enables the seamless buying, selling, storing and transfer of any cryptocurrency safely and securely in ZAR.'
      },
      {
        question: 'Why does my transaction take so long?',
        answer: `Fanfire's platform is extremely secure and depends on a number of protocols to facilitate a transaction. Some transactions might take longer than others to complete as the blockchain needs to be updated.`
      }
    ]
  }
]

